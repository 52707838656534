import { ArrowLeftOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetCheckingInData } from '../../../redux/actions/checking-in/checking-in'

const DupCheckedIn = (props) => {

	const [secondCounter, setSecondCounter] = useState(10)
	const dispatch = useDispatch()
	const { business } = useSelector(state => state.checkingIn)

	const goToHomePage = () => {
		const location = props.match.params.location;
		dispatch(resetCheckingInData())
		props.handleChangeCountry(business.country)
		props.history.push(`/${location}/checking-in/`);
	};

	useEffect(() => {

		if (secondCounter > 0) {
			const timer = setInterval(() => {
				setSecondCounter(prevSeconds => prevSeconds - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			goToHomePage()
		}
	})

	return (
		<Row
			justify="center"
			style={{
				padding: "20px",
				width: "100%",
				position: "relative",
				marginTop: "10%",
			}}
		>
			<Col md={16} lg={14} xl={12} sm={24} style={{ width: "100%" }}>
				<div className="box box-down green animate__animated animate__fadeInUp">
					{business && (
						<h2 style={{ fontWeight: "bold" }}>
							{business.name}
						</h2>
					)}

					<h2 style={{ textAlign: "center" }} className="animate__animated animate__zoomIn animate__delay-1s">
						<CheckCircleTwoTone twoToneColor="#52c41a" /> You have already checked in!
					</h2>
					<h2>
						Please consult our receptionist for further information or assistance.
					</h2>
					<Button type="primary" className="btn-grad" style={{ height: "50px", marginTop: "10px" }} size="large" onClick={() => goToHomePage()}>
						<ArrowLeftOutlined /> Back To Home Page (in {secondCounter}s)
					</Button>
				</div>
			</Col>
		</Row>
	)
}

export default DupCheckedIn