
import { message } from "antd";
export function NewMessageError(success, error, loading) {
    if (success) {
        
        success.split(",").map(succ => {
            return message.success({content: succ, duration: 2, className:"success-message", style: {
                marginTop: "50px",
                
            } })
        })
       
    }

    if (error) {
        error.split(",").map((err) => message.error({content: err, className:"error-message",  duration: 3, style: {
            marginTop: "50px",
            color: "#f5222d"
        } }));
    }
    if (loading) {
        const hide = message.loading({content: "Loading...", duration: 0, style: {
            marginTop: "50px",
        
        } });
        setTimeout(hide, (success || error) && 0);
    }
}
