// /**
//  * Replace input string with formated phone number
//  * @param {*} value
//  */
//  export const normalizePhone = (value, previousValue) => {
//     if (!value) return value;
//     const currentValue = value.replace(/[^\d]/g, "");
//     const cvLength = currentValue.length;

//     if (cvLength < 4) return currentValue;
//     if (cvLength < 7)
//         return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`;
//     return `(${currentValue.slice(0, 3)})-${currentValue.slice(
//         3,
//         6
//     )}-${currentValue.slice(6, 10)}`;
// };

// /**
//  * Check if the input string is a valid phone number
//  * formated: (555)-555-5555
//  * @param {*} value
//  */
// export function validatePhoneNumber(value) {
//     const phoneRegex = /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
//     if (!phoneRegex.test(value)) {
//         return false;
//     }
//     return true;
// }

// export function normalizeInput(value) {
//     if (!value) return value;

//     const currentValue = value.replace(/[^\d]/g, "");
//     const cvLength = currentValue.length;

//     if (cvLength < 4) return currentValue;
//     if (cvLength < 7) return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`;

//     return `(${currentValue.slice(0, 3)})-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
// }
import { parsePhoneNumber, getAsYouType } from 'awesome-phonenumber'

export function formatPhoneNumber(value, country) {
    const ayt = getAsYouType(country);
    return ayt.reset(value.replace(/\D+/g, ''));
}
export function validatePhoneNumber(value, country) {
    const pn = parsePhoneNumber(value, country)
    return pn.isValid() ? null : "Please enter a valid phone number!"
}
export function nationalFormatPhone(phone, country) {
    const pn = parsePhoneNumber(phone, country);
    return pn.isValid() ? pn.getNumber('national') : phone
}
export function internationalPhone(phone, country) {
    const pn = parsePhoneNumber(phone, country);
    return pn.isValid() ? pn.getNumber() : phone
}

export const countryPhoneCode = [
    { country: "US", code: "+1" },
    { country: "CA", code: "+1" },
    { country: "ES", code: "+34" }
]