import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    List,
    // Flex,
    Empty,
    //  Space
} from "antd";
import {
    // DeleteOutlined,
    ArrowRightOutlined,
    //  GiftOutlined, ExclamationCircleOutlined
} from "@ant-design/icons";
import Loading from "../../common/Loading/Loading";
// import moment from "moment";
// import { NewMessageError } from "../../common/MessageError/NewMessageError";
class Confirmation extends Component {
    state = {
        disableNextButton: false
    }

    componentDidMount() {
        const { services } = this.props
        if (services.length === 0 || services.some(service => !service.staff)) {
            this.setState({ disableNextButton: true })
        }
    }

    totalPrice = () => {
        const { services } = this.props

        let total = 0
        for (let service of services) {
            total += service.price
        }
        return total
    }

    getStaffName = (staffId) => {
        const { staffs } = this.props
        return staffs.find(staff => staff._id === staffId)?.name
    }

    render() {
        let { services, loading, currency } = this.props;

        if (loading) {
            return <Loading />;
        }
        const { disableNextButton } = this.state
        return (
            <div style={{ width: "100%" }}>
                <h4 style={{ padding: "0px 20px", fontSize: "1.2em" }}> Confirm your appointment</h4>

                <Row
                    justify="center"
                    style={{ width: "100%", position: "relative" }}
                >
                    <Col
                        xl={18}
                        lg={18}
                        md={18}
                        sm={23}
                        xs={23}
                        style={{ height: 'calc(100vh - 280px)', overflowY: 'auto', }}
                    >
                        {services.length > 0 ? (
                            <List
                                style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    background: "white",
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                                itemLayout="horizontal"
                                dataSource={services}
                                renderItem={(service, index) => (
                                    <List.Item >
                                        <List.Item.Meta

                                            title={<span style={{ fontSize: "1.2em" }}>{index + 1}. {service.name}</span>}
                                            description={<span style={{ fontSize: "1.2em" }}>{service.staff ? <span>with {this.getStaffName(service.staff)} at {service.start}</span> : <span style={{ color: "red" }}>No available staff</span>}</span>}
                                        />
                                        <span style={{ fontSize: "1.2em" }}>
                                            {service.priceType === "From" ? "From" : ""}{""} {currency}{service.price}
                                        </span>
                                    </List.Item>
                                )}
                            />

                            //         {/* {services.map((service, index) => {
                            //             return (
                            //                 <div
                            //                     key={service._id + index}
                            //                     className={service.staff ? "service-staff-box" : "service-staff-box red"}
                            //                     style={{ border: "1px solid #cbd5e1", padding: "15px", marginBottom: "10px" }}
                            //                 >
                            //                     <p style={{ fontSize: "1.3em", margin: "0px", marginBottom: "10px" }}> {index + 1}. {service.name}</p>
                            //                     <Flex justify="space-between" >
                            //                         {service.staff ? <span>with {this.getStaffName(service.staff)} at {service.start}</span> : <span style={{ color: "red" }}>No available staff</span>}

                            //                         <span > <span >
                            //                             {service.priceType === "From" ? "From" : ""}{""}
                            //                         </span>{" "}  {currency}{service.price}</span>
                            //                     </Flex>
                            //                 </div>
                            //             );
                            //         })} 
                            //     </Col>
                            // </Row>*/}
                        ) : (
                            <Empty description="No service seleted" />
                        )}
                    </Col>
                </Row >

                <Row
                    justify="center"
                    className="select-services-sticky-container"
                >
                    <Col xl={18} lg={18} md={18} sm={23} xs={23} style={{ margin: "15px", }}>
                        <Button
                            block
                            type="primary"
                            size="large"
                            className="btn-grad"
                            // shape="round"
                            style={{ height: "60px", zIndex: 39 }}
                            onClick={() => {
                                this.props.handleSubmit();
                            }}
                            disabled={services.length === 0 || disableNextButton}
                        >
                            Confirm <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
    staffs: state.checkingIn.staffs,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
    loading: state.checkingIn.loadingPendingServices,
    promotion: state.promotion.activePromotion,

});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
