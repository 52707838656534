import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, List, Avatar, Button } from 'antd'
import { RightOutlined, UserOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getServicesForWalkIn } from "../../../redux/actions/checking-in/checking-in"
import moment from 'moment'
import Loading from '../../common/Loading/Loading'
import { getHoursOfDay } from '../../../utils/util/constants'

class SelectStaff extends Component {
    state = {
        disableNextButton: false,
        closestHoursList: []
    }

    componentDidMount() {
        this.props.getServicesForWalkIn(this.props.location, this.props.staffs)
    }

    componentDidUpdate(prevProps) {
        const { blockTimes } = this.props

        if (prevProps.blockTimes !== blockTimes) {
            const hourList = this.asignServicesToHours()
            let closestHoursList = [{
                forStaff: null,
                closestHour: this.getClosestHour(null, hourList)
            }]
            for (let staff of this.props.staffs) {
                closestHoursList.push({
                    forStaff: staff,
                    closestHour: this.getClosestHour(staff, hourList)
                })
            }
            closestHoursList = closestHoursList.filter(hour => hour.closestHour.length !== 0)

            if (closestHoursList.length > 0) {
                this.chooseStaff(null, closestHoursList)
            }
            // console.log(closestHoursList)
            this.setState({
                closestHoursList
            })


        }
    }

    asignServicesToHours = () => {
        const hours = getHoursOfDay()
        let nowTime = moment().subtract(5, 'minutes');
        const { blockTimes, staffHours, salonInfo, services, staffs } = this.props
        let availableTimes = hours.filter(hour => {
            const time = hour.value
            return time.isBefore(nowTime, 'minutes') ? false : true
        })

        //map available staff to hour.services
        for (let hour of availableTimes) {
            const time = hour.value
            for (let service of services) {
                let hourService = JSON.parse(JSON.stringify(service));
                hourService.staffs = hourService.staffs.filter(staffID => {
                    //fill staff on staffs list
                    let isInStaffList = false
                    for (let staff of staffs) {
                        if (staff._id === staffID) {
                            isInStaffList = true
                            break
                        }
                    }
                    if (!isInStaffList) {
                        return false
                    }
                    //fill staff hour and after hour duration
                    const staffHour = staffHours.filter(staffHour => staffHour.staff === staffID)[0]
                    const endTimeAppointment = moment(time).add(service.duration, 'minutes')

                    if (!staffHour || !staffHour[nowTime.day()].isOpen) {
                        return false
                    }
                    let shifts = staffHour[nowTime.day()].shifts
                    let isInWorkingHours = false
                    for (let shift of shifts) {
                        const closeTime = moment(shift.close, "HH:mm")
                        const afterHourEndTime = moment(closeTime).add(salonInfo.appointmentSettings.afterHoursDuration, 'minute')
                        if (
                            time.isBetween(moment(shift.open, "HH:mm"), moment(shift.close, "HH:mm"), 'minutes', '[]')
                            && endTimeAppointment.isBetween(moment(shift.open, "HH:mm"), afterHourEndTime, 'minutes', '[]')
                        ) {
                            isInWorkingHours = true
                            break
                        }
                    }
                    if (!isInWorkingHours) {
                        return false
                    }

                    //fill block time
                    let staffBlockTime = blockTimes.filter(blockTime => blockTime.staff === staffID)
                    const endTime = moment(time).add(service.duration, 'minutes')
                    for (let blockTime of staffBlockTime) {
                        let blockTimeStart = moment(blockTime.start, 'HH:mm')
                        let blockTimeEnd = moment(blockTime.end, 'HH:mm')

                        if ((blockTimeStart.isBefore(endTime, 'minute') && blockTimeEnd.isAfter(time, 'minutes')) ||
                            (time.isBefore(blockTimeEnd, 'minute') && endTime.isAfter(blockTimeStart, 'minutes'))) {
                            return false
                        }
                    }

                    return true
                })
                hour.services.push(hourService)
            }
        }
        // fill hour that do not have services
        availableTimes = availableTimes.filter(hour => {
            hour.services = hour.services.filter(service => service.staffs.length > 0)
            return hour.services.length > 0
        })

        return availableTimes

    }

    getClosestHour = (staff, hourList) => {
        const { services } = this.props
        //RETURN  [{ startTime, staff, service }]

        let closestHours = []
        if (staff) {

            let staffHoursList = JSON.parse(JSON.stringify(hourList))
            for (let staffHour of staffHoursList) {
                staffHour.value = moment(staffHour.value)
            }
            //fill hour only for that staff
            staffHoursList = staffHoursList.filter(hour => {
                hour.services = hour.services.filter(service => service.staffs.includes(staff._id))
                return hour.services.length > 0
            })

            for (let hour of staffHoursList) {
                let isValidHour = true
                let durationFromStartAppointment = 0
                //check if hours contain service
                for (let service of services) {
                    let startTime = moment(hour.value).add(durationFromStartAppointment, 'minutes')
                    let foundHour = staffHoursList.find(checkHour =>
                        checkHour.value.isSame(startTime, 'minutes')
                        && checkHour.services.find(checkService => checkService._id === service._id)
                    )

                    if (foundHour) {
                        closestHours.push({
                            startTime: startTime,
                            staff: staff,
                            service: service
                        })
                    }
                    else {
                        isValidHour = false
                        closestHours = []
                        break
                    }
                    durationFromStartAppointment += service.duration
                }
                if (isValidHour) {
                    break
                }
            }
        }

        else {
            for (let hour of hourList) {
                let isValidHour = true
                let durationFromStartAppointment = 0
                //check if hours contain service
                for (let service of services) {
                    let startTime = moment(hour.value).add(durationFromStartAppointment, 'minutes')
                    let foundHour = hourList.find(checkHour =>
                        checkHour.value.isSame(startTime, 'minutes')
                        && checkHour.services.find(checkService => checkService._id === service._id)
                    )

                    if (foundHour) {
                        closestHours.push({
                            startTime: startTime,
                            staff: this.getPriorStaff(foundHour.services.find(checkService => checkService._id === service._id).staffs),
                            service: service
                        })
                    }
                    else {
                        isValidHour = false
                        closestHours = []
                        break
                    }
                    durationFromStartAppointment += service.duration
                }
                if (isValidHour) {
                    break
                }
            }
        }

        return closestHours
    }

    handleGoNext = () => {
        const location = this.props.location;

        if (this.props.isClientDetailMissing()) {

            this.props.history.push(`/${location}/checking-in/select/client-form`);
        }
        else {
            this.props.history.push(`/${location}/checking-in/select/confirmation`);
        }
    }

    getFilteredStaffs = () => {
        const { staffs, staffHours, services } = this.props
        let filteredStaffs = staffs

        const today = moment().day()
        filteredStaffs = filteredStaffs
            .filter((staff) => {
                //filter staff that does not do all services
                for (let service of services) {
                    if (!service.staffs.includes(staff._id)) {
                        return false
                    }
                }
                //filter staff hour
                const staffHour = staffHours.filter(staffHour => staffHour.staff === staff._id)[0]
                if (!staffHour || !staffHour[today].isOpen) {
                    return false
                }
                let shifts = staffHour[today].shifts
                const foundShift = shifts.filter(shift => shift.open <= moment().format("HH:mm") && shift.close >= moment().format("HH:mm"))
                if (foundShift.length === 0) {
                    return false
                }
                //fill staff does not have closest hour
                let closestTime = this.state.closestHoursList.find(hour => hour.forStaff?._id === staff._id)
                if (!closestTime) {
                    return false
                }

                return true

            })

        return filteredStaffs
    }

    chooseStaff = (preferedStaff, closestHoursList) => {
        const { services } = this.props
        this.props.handleUpdatePreferedStaff(preferedStaff)
        let closestHour = closestHoursList.find(hour => {
            if (!preferedStaff) {
                return hour.forStaff === null
            } else {
                return hour.forStaff !== null && hour.forStaff._id === preferedStaff._id
            }

        }).closestHour

        for (let i = 0; i < services.length; i++) {
            this.props.handleStaffChange(closestHour[i].staff._id, closestHour[i].staff.name, closestHour[i].startTime, i)

        }
    }

    getPriorStaff = (serviceStaffs) => {
        const { staffs } = this.props
        return staffs
            .find(staff => {
                let priorStaff = null
                for (let serviceStaff of serviceStaffs) {
                    if (staff._id === serviceStaff) {
                        priorStaff = staff
                        break
                    }
                }
                return priorStaff
            })
    }

    getAvaiableTime = (staffId) => {
        const { closestHoursList } = this.state
        let staffClosestHour = closestHoursList.find(hour => hour.forStaff?._id === staffId).closestHour[0].startTime
        if (staffClosestHour.isBetween(moment().subtract(15, 'minutes'), moment().add(15, 'minutes'), 'minutes', '[]')) {
            return <div style={{ color: "green" }}>
                Available now
            </div>
        }
        else if (staffClosestHour.diff(moment(), 'minutes') <= 30) {
            return <div style={{ color: "orange" }}>
                Available in {staffClosestHour.diff(moment(), 'minutes')} minutes
            </div>
        }
        else {
            return <div>
                Available at {staffClosestHour.format('HH:mm')}
            </div>
        }
    }

    render() {
        const { loading } = this.props;
        const { disableNextButton, closestHoursList } = this.state;

        if (loading) {
            return <Loading />
        }
        const filterStaffs = this.getFilteredStaffs()

        return (<div style={{ width: "100%" }}>
            <h4 style={{ padding: "0px 20px", fontSize: "1.2em" }}>Select your staff</h4>
            <Row
                justify="center"
                style={{ width: "100%", position: "relative" }}
            >
                <Col xl={18} lg={18} md={18} sm={23} xs={23} style={{ height: 'calc(100vh - 280px)', overflowY: 'auto', }}>
                    <List
                        className="booking-staffList"
                        style={{ borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "white", overflow: "hidden" }}
                        dataSource={filterStaffs}
                        locale={{ emptyText: <p style={{ fontSize: "1.1em", color: "#1f2937" }}>Currently, there is no staff available for the selected services. Please talk to the front desk. Thank you!</p> }}
                        header={
                            filterStaffs.length > 0 && <List.Item
                                onClick={() => this.chooseStaff(null, closestHoursList)}
                                className="booking-list-item"
                                style={{
                                    padding: "10px",
                                    background: this.props.preferedStaff === null && "#00c2cb",
                                    color: this.props.preferedStaff === null ? "white" : "#272727",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        style={{ marginRight: "20px", background: "#e5f1ff", color: "#2f7fff" }}
                                        size={56}
                                        icon={<UserOutlined />}
                                    />
                                    <div style={{ display: "inline-block" }}>
                                        <div>No preference</div>
                                        <div style={{ fontWeight: "normal", fontSize: "14px" }}>Maximum availability</div>
                                    </div>
                                </div>
                                <RightOutlined style={{ float: "right", display: window.innerWidth < 480 ? "none" : null }} />
                            </List.Item>
                        }
                        renderItem={staff => (
                            <List.Item
                                onClick={() => this.chooseStaff(staff, closestHoursList)}
                                className="booking-list-item"
                                style={{
                                    padding: "10px",
                                    background: this.props.preferedStaff === staff && "#00c2cb",
                                    color: this.props.preferedStaff === staff ? "white" : "#272727",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        style={{ marginRight: "20px", background: "#e5f1ff", color: "#2f7fff" }}
                                        size={56} >
                                        {staff.name.charAt(0)}
                                    </Avatar>
                                    <div style={{ display: "inline-block" }}>
                                        <div>{staff.name}</div>
                                        <div style={{ fontWeight: "normal", fontSize: "14px" }}>{this.getAvaiableTime(staff._id)}</div>
                                    </div>
                                </div>
                                <RightOutlined style={{ float: "right", display: window.innerWidth < 480 ? "none" : null }} />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row justify="center" className="select-services-sticky-container">
                <Col xl={18} lg={18} md={18} sm={23} xs={23} style={{ margin: "15px", }}>
                    <Button
                        block
                        type="primary"
                        size="large"
                        // shape="round"
                        style={{
                            height: "60px", display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: 39,
                            backgroundColor: disableNextButton || filterStaffs.length === 0 ? "#e5e7eb" : "",
                        }}
                        className="btn-grad"
                        onClick={() =>
                            this.handleGoNext()
                        }
                        disabled={disableNextButton || filterStaffs.length === 0}
                    >
                        <span>
                            {this.props.services.length > 0
                                ? `${this.props.services.length} ${this.props.services.length === 1 ? "service" : "services"}`
                                : ""}
                        </span>
                        <span> Next <ArrowRightOutlined /></span>
                    </Button>
                </Col>
            </Row>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    salonInfo: state.checkingIn.business,
    staffs: state.checkingIn.staffs,
    business: state.checkingIn.business,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
    blockTimes: state.checkingIn.blockTimes,
    loading: state.checkingIn.loadingPendingServices
})

const mapDispatchToProps = (dispatch) => {
    return {
        getServicesForWalkIn: (business, staffList) => dispatch(getServicesForWalkIn(business, staffList)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectStaff)
