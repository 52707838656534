import {
    GET_CHECKING_IN_SALON_INFO_STARTED,
    GET_CHECKING_IN_SALON_INFO_SUCCESS,
    GET_CHECKING_IN_SALON_INFO_FAILURE,
    GET_PHONE_APPOINTMENTS_STARTED,
    GET_PHONE_APPOINTMENTS_SUCCESS,
    GET_PHONE_APPOINTMENTS_FAILURE,
    CHECKING_IN_APPOINTMENTS_STARTED,
    CHECKING_IN_APPOINTMENTS_SUCCESS,
    CHECKING_IN_APPOINTMENTS_FAILURE,
    CHECKING_IN_WALK_IN_APPOINTMENT_STARTED,
    CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS,
    CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE,
    CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_STARTED,
    CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_SUCCESS,
    CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_FAILURE,
    // CREATE_BUSINESS_CLIENT_STARTED,
    // CREATE_BUSINESS_CLIENT_SUCCESS,
    // CREATE_BUSINESS_CLIENT_FAILURE,
    // UPDATE_BUSINESS_CLIENT_STARTED,
    // UPDATE_BUSINESS_CLIENT_SUCCESS,
    // UPDATE_BUSINESS_CLIENT_FAILURE,
    GET_SERVICES_FOR_WALK_IN_STARTED,
    GET_SERVICES_FOR_WALK_IN_SUCCESS,
    GET_SERVICES_FOR_WALK_IN_FAILURE,
    REMOVE_ERROR_MESSAGE,
    RESET_CHECKING_IN_DATA,
    REDIRECT_TO_NOTFOUND,
} from "../../constants/checking-in/checking-in";
import moment from "moment"


const initialState = {
    appointments: [],
    appointmentServices: [],
    blockTimes: [],
    businessClient: { name: "Dash Booking" },
    clientLoyalty: null,
    isFetchingAppointment: false,
    business: null,
    staffs: null,
    staffHours: null,
    holidays: [],
    menu: null,
    phone: null,
    loading: false,
    loadingPendingServices: false,
    error: null,
    message: null,
    redirectToNotFound: false,
    alreadyCheckedIn: false,
    loyalty: null
};

export default function checkingIn(state = initialState, action) {
    switch (action.type) {
        case GET_SERVICES_FOR_WALK_IN_STARTED:
            return {
                ...state,
                loadingPendingServices: true,
                error: null,
                message: null,
            };
        case GET_SERVICES_FOR_WALK_IN_SUCCESS:
            return {
                ...state,
                loadingPendingServices: false,
                blockTimes: action.payload.data.blockTimes,
                staffs: action.payload.data.sortedStaffList
            };
        case GET_SERVICES_FOR_WALK_IN_FAILURE:
            return {
                ...state,
                loadingPendingServices: false,
                error: action.payload.error,
            };

        case GET_CHECKING_IN_SALON_INFO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_CHECKING_IN_SALON_INFO_SUCCESS:
            const holidays = action.payload.data.holidays
            let staffs = action.payload.data.staffs
            let staffHours = action.payload.data.staffHours
            let todayHolidaysAndSpecialHours = holidays.filter(holiday => moment().isBetween(holiday.from, holiday.to))

            for (let staff of staffs) {
                let businessHoliday = null
                let staffHoliday = null
                let staffSpecialHour = null
                let staffHour = staffHours.find(hour => hour.staff === staff._id)

                for (let holiday of todayHolidaysAndSpecialHours) {
                    if (holiday.isSpecialHour && holiday.staffs.includes(staff._id)) {
                        staffSpecialHour = holiday
                    } else {
                        if (holiday.type === "Staff" && holiday.staffs.includes(staff._id)) {
                            staffHoliday = holiday
                        } else if (holiday.type === "Business") {
                            businessHoliday = holiday
                        }
                    }
                }
                if (staffSpecialHour) {
                    staffHour[moment().day()] = {
                        isOpen: true,
                        shifts: staffSpecialHour.shifts
                    }
                } else if (staffHoliday || businessHoliday) {
                    staffs = staffs.filter(stff => stff._id !== staff._id)
                }
            }

            return {
                ...state,
                business: action.payload.data.business,
                businessHour: action.payload.data.businessHour,
                menu: action.payload.data.menu,
                staffs,
                staffHours,
                holidays: action.payload.data.holidays,
                loyalty: action.payload.data.loyalty,
                loading: false,
                error: null,
                message: null,
            };
        case GET_CHECKING_IN_SALON_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_PHONE_APPOINTMENTS_STARTED:
            return {
                ...state,
                appointments: [],
                businessClient: null,
                isFetchingAppointment: false,
                phone: null,
                loading: true,
                error: null,
                message: null,
            };
        case GET_PHONE_APPOINTMENTS_SUCCESS:
            // let businessClient = user
            // if(action.payload.data.businessClient) {
            //     businessClient = action.payload.data.businessClient
            // }

            return {
                ...state,
                appointments: action.payload.data.appointments,
                appointmentServices: action.payload.data.appointmentServices,
                businessClient: action.payload.data.businessClient,
                isFetchingAppointment: true,
                phone: action.phone,
                alreadyCheckedIn: action.payload.data.alreadyCheckedIn,
                loading: false,
                error: null,
                message: null,
            };
        case GET_PHONE_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CHECKING_IN_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CHECKING_IN_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                clientLoyalty: action.payload.data,
                message:
                    "Thank you for visiting our business. Please have a seat, we will serve you soon",
            };
        case CHECKING_IN_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                clientLoyalty: action.payload.data,
                message:
                    "Walk-in appointment checked-in successfully. Please have a seat, we will serve you soon.",
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_SUCCESS:
            return {
                ...state,
                clientLoyalty: action.payload.data,
                loading: false,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };


        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                redirectToNotFound: false,
                error: null,
                message: null,
            };
        }
        case REDIRECT_TO_NOTFOUND: {
            return {
                ...state,
                redirectToNotFound: true,
            };
        }
        case RESET_CHECKING_IN_DATA: {
            return {
                ...state,
                appointments: [],
                businessClient: null,
                isFetchingAppointment: false,
                phone: null,
                loading: false,
                error: null,
                message: null,
                redirectToNotFound: false,
                alreadyCheckedIn: false,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
