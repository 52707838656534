import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input, Modal, List } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import ServicesAddOns from "./ServicesAddOns";


function adjustBrightness(color, amount) {
    let usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color, 16);

    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00FF) + amount;
    let b = (num & 0x0000FF) + amount;

    r = Math.max(Math.min(255, r), 0);
    g = Math.max(Math.min(255, g), 0);
    b = Math.max(Math.min(255, b), 0);

    return (usePound ? "#" : "") + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}


export class Services extends Component {
    state = {
        search: "",
    };


    handleGoNext = () => {
        let { services } = this.props;
        let staffIds = services[0].staffs
        services.forEach(service => {
            staffIds = staffIds.filter(staff => service.staffs.includes(staff))
        })
        const location = this.props.location;

        if (staffIds.length > 0) {
            this.props.history.push(`/${location}/checking-in/select/staff`);
        }

        else if (this.props.isClientDetailMissing()) {
            this.props.history.push(`/${location}/checking-in/select/client-form`);
        }
        else {
            this.props.history.push(`/${location}/checking-in/select/confirmation`);
        }
    }

    searchOnChange = (search) => {
        this.setState({ search })
    };

    servicesWithSearch = (menu, search) => {
        let servicesWithSearch = menu.map((category) => {
            const newServices = [...category.services]
            const newCategory = { ...category, newServices }
            return newCategory
        });

        if (search !== "") {
            servicesWithSearch = servicesWithSearch.filter((category) => {
                category.services = category.services.filter(service => {
                    return service.name.toLowerCase().includes(search.toLowerCase())
                })
                return !!category
            })
        }

        return servicesWithSearch;
    };

    isServiceSelected = (service) => {
        const { services } = this.props;
        if (service.type === "Combo") {
            return services.some(serv => service.subServices.includes(serv._id))
        }
        else {
            return services.some(serv => serv._id === service._id)
        }
    }

    render() {
        const { menu, services, currency } = this.props;
        const { search } = this.state;

        const servicesWithSearch = this.servicesWithSearch(menu, search);

        return (<div style={{ width: "100%", }}>
            <h4 style={{ padding: "0px 20px", fontSize: "1.2em" }}>Select your services</h4>
            {/* <Row justify="center" style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                    Select your services
                </Col>
            </Row> */}
            <Row justify="center"  >

                <Col xl={18} lg={18} md={18} sm={23} xs={23} className="service-container" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: 'calc(100vh - 280px)', overflowY: 'auto', }}>
                    <Input
                        placeholder="Find services"
                        allowClear
                        onChange={(e) => this.searchOnChange(e.target.value)}
                        size="large"
                        suffix={<SearchOutlined style={{ color: "lightgrey" }} />}
                        style={{ width: "100%", marginBottom: "10px" }}
                    />

                    {servicesWithSearch !== null &&
                        servicesWithSearch.filter(cat => cat.services.length > 0).map((category) => {
                            return (
                                <div key={category._id}>
                                    <h2 style={{
                                        color: "#334155",
                                        position: "sticky",
                                        top: "-1rem",
                                        left: "0",
                                        padding: "10px 0px",
                                        margin: "0px",
                                        zIndex: 1,
                                        backgroundColor: category.color ? `${category.color}` : "rgba(165, 243, 252, 1)",
                                        borderRadius: "8px",
                                        paddingLeft: "10px",
                                        background: category.color
                                            ? `linear-gradient(to right, ${category.color}, ${adjustBrightness(category.color, 20)})`
                                            : "linear-gradient(to right, rgba(165, 243, 252, 1), rgba(100, 200, 250, 1))", // Fallback gradient

                                    }}> {category.name}</h2>
                                    <List

                                        // header={<h2 style={{
                                        //     color: "#a3a3a3",
                                        //     position: "sticky",
                                        //     top: "0.5rem",
                                        //     left: "0",
                                        //     background: "white",
                                        //     margin: "0px",
                                        //     zIndex: 1, // Ensure the header stays on top

                                        // }}> {category.name}</h2>}
                                        itemLayout="horizontal"
                                        dataSource={category.services.filter(service => !service.isSubService)}
                                        renderItem={(service, index) => (
                                            <List.Item onClick={() => this.props.handleSelectService(service)}
                                                className={
                                                    "modal-service " +
                                                    (this.isServiceSelected(service) ? "modal-service-selected" : "")
                                                }>
                                                <List.Item.Meta
                                                    title={<span style={{ paddingLeft: "5px", fontSize: "1.1em" }}>{service.name}</span>}
                                                    description={service.description}
                                                />
                                                <span
                                                    style={{
                                                        color: "#4b5563",
                                                        paddingRight: "8px",
                                                        fontSize: "1.1em"
                                                    }}
                                                >
                                                    {
                                                        service.priceType === "From" ? "From" : ""
                                                    }
                                                    {" "}
                                                    {currency}
                                                    {
                                                        service.price
                                                    }
                                                </span>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            );


                            // return (
                            //     category.services.length > 0 && (
                            //         <div
                            //             key={category._id}
                            //             style={{ margin: "0 0 15px" }}
                            //         >
                            //             <h2
                            //                 style={{
                            //                     margin: "5px 0",
                            //                     // borderLeft: `3px solid ${category.color}`,
                            //                     borderRadius: "8px",
                            //                     backgroundColor: category.color ? `${category.color}CC` : "rgba(165, 243, 252, 0.8)",
                            //                     padding: "5px 8px",

                            //                     // paddingLeft: "10px",
                            //                 }}
                            //             >
                            //                 {category.name}
                            //             </h2>
                            //             <Row>
                            //                 {category.services
                            //                     .filter(service => !service.isSubService)
                            //                     .map(
                            //                         (service) => {
                            //                             return (
                            //                                 <div
                            //                                     key={
                            //                                         service._id
                            //                                     }
                            //                                     onClick={() => this.props.handleSelectService(service)}
                            //                                     className={
                            //                                         "modal-service " +
                            //                                         (this.isServiceSelected(service) ? "modal-service-selected" : "")

                            //                                     }
                            //                                     style={{ border: "1px solid #cbd5e1", }}
                            //                                 >
                            //                                     <div
                            //                                         style={{
                            //                                             margin: "0",
                            //                                         }}
                            //                                     >
                            //                                         <Flex justify="space-between" style={{ fontSize: "1.3em" }}>
                            //                                             <span >
                            //                                                 {service.name}
                            //                                             </span>


                            //                                             <span
                            //                                                 style={{
                            //                                                     color: "#8c8c8c",

                            //                                                 }}
                            //                                             >
                            //                                                 {
                            //                                                     service.priceType === "From" ? "From" : ""
                            //                                                 }
                            //                                                 {" "}
                            //                                                 {currency}

                            //                                                 {
                            //                                                     service.price
                            //                                                 }

                            //                                             </span>
                            //                                         </Flex>


                            //                                     </div>
                            //                                     {service.description >
                            //                                         "" && (
                            //                                             <span
                            //                                                 style={{
                            //                                                     color: "grey",
                            //                                                 }}
                            //                                             >
                            //                                                 {
                            //                                                     service.description
                            //                                                 }
                            //                                             </span>
                            //                                         )}
                            //                                 </div>
                            //                             );
                            //                         }
                            //                     )}
                            //             </Row>
                            //         </div>
                            //     )
                            // );
                        })}

                </Col>
            </Row>
            <Row justify="center" className="select-services-sticky-container">
                <Col xl={18} lg={18} md={18} sm={23} xs={23} style={{ margin: "15px", }}>
                    <Button
                        block
                        type="primary"
                        className="btn-grad"
                        size="large"
                        // shape="round"
                        style={{
                            height: "60px", display: "flex", justifyContent: "space-between", alignItems: "center",
                            backgroundColor: services.length === 0 ? "#e5e7eb" : "", // Set your desired color here

                        }}
                        onClick={() =>
                            this.handleGoNext()
                        }
                        disabled={
                            services.length === 0
                        }

                    >
                        <span>
                            {services.length > 0
                                ? `${services.length} ${services.length === 1 ? "service" : "services"}`
                                : ""}
                        </span>
                        <span> Next <ArrowRightOutlined /></span>

                    </Button>
                </Col>
            </Row>
            <Modal
                title="Add-Ons Services (Optional)"
                open={this.props.isModalAddOnsOpen}
                onCancel={() => this.props.handleOpenAddOnsModal(false)}
                footer={null}
                style={{ padding: "0px", }}
            >
                <ServicesAddOns
                    currentService={this.props.currentService}
                    services={services}
                    currency={currency}
                    handleSelectService={this.props.handleSelectService}
                    handleOpenAddOnsModal={this.props.handleOpenAddOnsModal}
                    addAddOnsToServices={this.props.addAddOnsToServices}
                />
            </Modal>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
