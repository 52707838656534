import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Button, Select } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { formatPhoneNumber, internationalPhone, nationalFormatPhone, validatePhoneNumber } from "../../utils/FormatPhoneNumber/FormatPhoneNumber";
import { getPhoneAppointments } from "../../redux/actions/checking-in/checking-in";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import moment from "moment";
import { countryPhoneCode } from "../../utils/util/utility";

const { Option } = Select;
class CheckingPhoneContainer extends Component {
    state = {
        phone: "",
        error: "",
        loading: false,
    };

    inputRef = React.createRef();

    componentDidMount() {
        if (this.inputRef.current && window.innerWidth < 768) {
            this.inputRef.current.focus();
        }

        if (this.props.phone) {
            this.setState({
                phone: nationalFormatPhone(
                    this.props.phone,
                    this.props.country
                ),
            });
        }
        if (this.props.isClientUrl) {
            const localClientData = JSON.parse(
                localStorage.getItem("checkingInUser")
            );
            if (localClientData && localClientData.phone) {
                this.setState({
                    phone: nationalFormatPhone(
                        localClientData.phone,
                        this.props.country
                    ),
                });
            }
        }
    }

    isPhoneNumberValid = (phone) => {
        return validatePhoneNumber(phone, this.props.country);
    }

    // componentDidUpdate(prevProps) {
    //     if(prevProps.phone !== this.props.phone){

    //         this.setState({
    //             phone: this.props.phone
    //         })
    //     }
    // }

    handleChange = (e) => {
        this.setState({
            phone: formatPhoneNumber(e.target.value, this.props.country),
        });
    };

    handleChangeCountry = (value) => {
        this.props.handleChangeCountry(value);
        this.setState({
            phone: formatPhoneNumber(this.state.phone, value),
        });
    };

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;

        if (!executeRecaptcha) {
            return null;
        }

        return await executeRecaptcha();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        // reCAPTCHA verification
        const token = await this.handleVerifyRecaptcha();

        const error = validatePhoneNumber(this.state.phone, this.props.country);
        const getPhoneAppointments = this.props.getPhoneAppointments;
        const today = moment().format("YYYY-MM-DD");
        const unformattedPhone = internationalPhone(
            this.state.phone,
            this.props.country
        );
        this.setState({ error }, () => {
            if (!error) {
                getPhoneAppointments(
                    this.props.location,
                    unformattedPhone,
                    token,
                    today
                );
            }
        });
    };

    render() {
        const { business, country, loyalty } = this.props;
        const loyaltyTitle = loyalty?.title || "";
        const loyaltyDescription = loyalty?.description || "";
        if (business) {
            return (

                <Row
                    justify='center'
                    style={{ margin: "20px", marginTop: "10%", backgroundColor: "white" }}
                    className='box box-down cyan'
                >

                    <Col
                        md={12}
                        lg={12}
                        xl={12}
                        sm={24}

                    >
                        <div >
                            <form onSubmit={this.handleSubmit}>
                                {business && <h1 >{business.name} </h1>}



                                <h3 style={{ color: "#71717a", margin: "10px 0px" }}>Please enter your phone number: </h3>
                                <Input
                                    ref={this.inputRef}
                                    onChange={this.handleChange}
                                    placeholder='Phone number'
                                    value={this.state.phone}
                                    size='large'
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                    }}
                                    type='text'
                                    inputMode='tel'
                                    allowClear
                                    addonBefore={
                                        <Select
                                            style={{ width: "110px" }}
                                            className="custom-input"
                                            value={country}
                                            onChange={this.handleChangeCountry}
                                        >
                                            {countryPhoneCode.map((ctr) => (
                                                <Option
                                                    value={ctr.country}
                                                    key={ctr.country}
                                                >
                                                    {ctr.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    }
                                />
                                {this.state.error && (
                                    <p className='checking-in-phone-error'>
                                        {this.state.error}
                                    </p>
                                )}



                                <Row justify='center'>
                                    <Button
                                        type='primary'
                                        loading={this.state.loading}
                                        size='large'
                                        htmlType='submit'
                                        className="btn-grad"
                                        style={{ width: "100%", height: "50px", marginTop: "10px" }}
                                        disabled={this.isPhoneNumberValid(this.state.phone)}
                                    >
                                        Next <SendOutlined />
                                    </Button>
                                </Row>
                                {loyaltyTitle && <Row justify="center">
                                    <div
                                        className='loyalty-description animate__animated animate__fadeInUp'
                                        style={{
                                            textAlign: "center",

                                        }}
                                    >
                                        <h3 style={{ margin: "10px 0px", fontSize: "1.4em" }}>{loyaltyTitle}</h3>
                                        <p style={{ margin: "10px 0px", color: "white", fontSize: "1.2em" }}>{loyaltyDescription}</p>
                                    </div>
                                </Row>}
                            </form>
                        </div>
                    </Col>

                    <Col
                        md={12}
                        lg={12}
                        xl={12}
                        xs={0}
                        sm={0} // Hide on small screens
                        className=''
                    >

                        <Row justify='center'>
                            {[
                                [1, 2, 3],
                                [4, 5, 6],
                                [7, 8, 9],
                                ["x", 0, "<"]
                            ].map((row, rowIndex) => (
                                <Row key={rowIndex} justify='center' style={{ width: "100%" }}>
                                    {row.map((num) => (
                                        <Button
                                            key={num}
                                            className="num-pad gradient-background-button-squared"

                                            style={{
                                                width: "80px",
                                                height: "80px",
                                                margin: "5px",
                                                // fontSize: "20px",
                                                // borderRadius: "10px",
                                                border: num === "x" ? "1px solid red" : "",
                                                color: num === "x" ? "red" : "black",
                                                boxShadow: num === "x" ? "6px 4px #f87171" : num === "<" ? "6px 4px #f59e0b" : "6px 4px #00c2cb",

                                            }}
                                            onClick={() => {
                                                this.setState({ error: "", loading: false });
                                                if (num === "x") this.setState({ phone: "" });
                                                if (num === "<") {
                                                    this.setState((prevState) => ({
                                                        phone: prevState.phone.slice(0, -1),
                                                    }));
                                                } else {
                                                    this.setState((prevState) => ({
                                                        phone: formatPhoneNumber(
                                                            prevState.phone + num.toString(),
                                                            this.props.country
                                                        ),
                                                    }));
                                                }
                                            }}
                                        >
                                            {num === "*" ? "" : num}
                                        </Button>
                                    ))}
                                </Row>
                            ))}
                        </Row>
                    </Col>

                    <div style={{ margin: "10px", backgroundColor: "white" }}>
                        <small
                            style={{
                                color: "grey",
                                marginTop: "20px",
                            }}
                        >
                            This site is protected by reCAPTCHA and
                            the Google{" "}
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href='https://policies.google.com/privacy'
                            >
                                Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href='https://policies.google.com/terms'
                            >
                                Terms of Service
                            </a>{" "}
                            apply.
                        </small>
                        <div style={{ padding: "5px", }}>
                            <p style={{ textAlign: "center" }}>
                                Powered by Dash Booking Inc. &copy;{" "}
                                {moment().format("YYYY")}
                            </p>
                        </div>
                    </div>

                </Row>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
    appointments: state.checkingIn.appointments,
    phone: state.checkingIn.phone,
    loyalty: state.checkingIn.loyalty,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPhoneAppointments: (business, phone, token, today) =>
            dispatch(getPhoneAppointments(business, phone, token, today)),
    };
};

export default withGoogleReCaptcha(
    connect(mapStateToProps, mapDispatchToProps)(CheckingPhoneContainer)
);
