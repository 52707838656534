
const moment = require('moment')

const HOURS = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const MINUTES = [0, 15, 30, 45]
const SECOND = 0


export function getHoursOfDay(date = null) {
    let HOURS_OF_DAY = []
    HOURS.forEach(hour => {
        MINUTES.forEach(minute => {
            const time = date ? moment(date, "YYYY-MM'DD").set({ hour, minute, 'second': SECOND }) : moment().set({ hour, minute, 'second': SECOND })
            const data = {
                value: time,
                display: time.format("h:mm a"),
                services: []
            }
            HOURS_OF_DAY = [...HOURS_OF_DAY, data]
        })


    })
    return HOURS_OF_DAY
}



export const monthColors = [
    { month: "Jan", color: "#fbab7e" },
    { month: "Feb", color: "#0093e9" },
    { month: "Mar", color: "#3cba92" },
    { month: "Apr", color: "#6a85b6" },
    { month: "May", color: "#fa709a" },
    { month: "Jun", color: "#80d0c7" },
    { month: "Jul", color: "#ff0844" },
    { month: "Aug", color: "#ff5858" },
    { month: "Sep", color: "#0093e9" },
    { month: "Oct", color: "#2a5298" },
    { month: "Nov", color: "#6f86d6" },
    { month: "Dec", color: "#5f0a87" }
]


// export const HOURS_OF_DAY = [
//     moment().set({'hour':10, 'minute': 0 }),
//     moment().set({'hour':10, 'minute': 15 }),,
//     moment().set({'hour':10, 'minute': 30 }),,
//     moment().setHours(10,45),
//     moment().setHours(11,0),
//     moment().setHours(11,15),
//     moment().setHours(11,30),
//     moment().setHours(11,45),
//     moment().setHours(12,0),
//     moment().setHours(12,15),
//     moment().setHours(12,30),
//     moment().setHours(12,45),
//     moment().setHours(13,0),
//     moment().setHours(13,15),
//     moment().setHours(13,30),
//     moment().setHours(13,45),
//     moment().setHours(14,0),
//     moment().setHours(14,15),
//     moment().setHours(14,30),
//     moment().setHours(14,45),
//     moment().setHours(15,0),
//     moment().setHours(15,15),
//     moment().setHours(15,30),
//     moment().setHours(15,45),
//     moment().setHours(16,0),
//     moment().setHours(16,15),
//     moment().setHours(16,30),
//     moment().setHours(16,45),
//     moment().setHours(17,0),
//     moment().setHours(17,15),
//     moment().setHours(17,30),
//     moment().setHours(17,45),
//     moment().setHours(18,0),
//     moment().setHours(18,15),
//     moment().setHours(18,30),
//     moment().setHours(18,45),
//     moment().setHours(19,0),
//     moment().setHours(19,15),
//     moment().setHours(19,30),
//     moment().setHours(19,45),
//     moment().setHours(20,0),
//     moment().setHours(20,15),
//     moment().setHours(20,30),
//     moment().setHours(20,45),
//     moment().setHours(21,0),
//     moment().setHours(21,15),
//     moment().setHours(21,30),
//     moment().setHours(21,45),
// ]