import axios from "axios";


let baseURL = "https://checkin-api.dashbooking.com"
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  baseURL = null
}
export default axios.create({
    withCredentials : true,
    baseURL
    // baseURL: "http://localhost:3007"
    // headers: { common: { Authorization: `Bearer ${authToken}` } }
});
