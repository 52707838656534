export const GET_CHECKING_IN_SALON_INFO_STARTED = "GET_CHECKING_IN_SALON_INFO_STARTED"
export const GET_CHECKING_IN_SALON_INFO_SUCCESS = "GET_CHECKING_IN_SALON_INFO_SUCCESS"
export const GET_CHECKING_IN_SALON_INFO_FAILURE = "GET_CHECKING_IN_SALON_INFO_FAILURE"

export const GET_PHONE_APPOINTMENTS_STARTED = "GET_PHONE_APPOINTMENTS_STARTED"
export const GET_PHONE_APPOINTMENTS_SUCCESS = "GET_PHONE_APPOINTMENTS_SUCCESS"
export const GET_PHONE_APPOINTMENTS_FAILURE = "GET_PHONE_APPOINTMENTS_FAILURE"

export const CHECKING_IN_APPOINTMENTS_STARTED = "CHECKING_IN_APPOINTMENTS_STARTED"
export const CHECKING_IN_APPOINTMENTS_SUCCESS = "CHECKING_IN_APPOINTMENTS_SUCCESS"
export const CHECKING_IN_APPOINTMENTS_FAILURE = "CHECKING_IN_APPOINTMENTS_FAILURE"

export const CHECKING_IN_WALK_IN_APPOINTMENT_STARTED = "CHECKING_IN_WALK_IN_APPOINTMENT_STARTED"
export const CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS = "CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS"
export const CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE = "CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE"

export const CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_STARTED = "CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_STARTED"
export const CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_SUCCESS = "CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_SUCCESS"
export const CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_FAILURE = "CHECKING_IN_WALK_IN_APPOINTMENT_NO_SERVICES_FAILURE"


// export const CREATE_BUSINESS_CLIENT_STARTED = "CREATE_BUSINESS_CLIENT_STARTED"
// export const CREATE_BUSINESS_CLIENT_SUCCESS = "CREATE_BUSINESS_CLIENT_SUCCESS"
// export const CREATE_BUSINESS_CLIENT_FAILURE = "CREATE_BUSINESS_CLIENT_FAILURE"

// export const UPDATE_BUSINESS_CLIENT_STARTED = "UPDATE_BUSINESS_CLIENT_STARTED"
// export const UPDATE_BUSINESS_CLIENT_SUCCESS = "UPDATE_BUSINESS_CLIENT_SUCCESS"

export const GET_SERVICES_FOR_WALK_IN_STARTED = "GET_SERVICES_FOR_WALK_IN_STARTED"
export const GET_SERVICES_FOR_WALK_IN_SUCCESS = "GET_SERVICES_FOR_WALK_IN_SUCCESS"
export const GET_SERVICES_FOR_WALK_IN_FAILURE = "GET_SERVICES_FOR_WALK_IN_FAILURE"


export const RESET_CHECKING_IN_DATA = "RESET_CHECKING_IN_DATA"

export const REDIRECT_TO_NOTFOUND = "REDIRECT_TO_NOTFOUND"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"