import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";

//load the saved user data from localstorage for Redux state
// function loadFromLocalStorage() {
//     try {
//         let user = {
//             authenticated: false
//         };
//         // const serializedUser = localStorage.getItem("user") 
//         const serializedUser = localStorage.getItem("user") 

//         if (serializedUser === null) {
//             return Object.assign({}, user);
//         }
//         user = JSON.parse(serializedUser);
//         return Object.assign({}, user);
//     } catch (e) {
//         const user = {
//             authenticated: false
//         };
//         return Object.assign({}, user);
//     }
// }
// //User Data load from localStorage
// const persistedState = loadFromLocalStorage();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    // persistedState,
    storeEnhancers(applyMiddleware(thunk))
);

export default store;
