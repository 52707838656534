import {
    GET_ACTIVE_PROMOTIONS_STARTED,
    GET_ACTIVE_PROMOTIONS_SUCCESS,
    GET_ACTIVE_PROMOTIONS_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/promotion/promotion";

import API from "../../../utils/API/API"


export const getActivePromotions = (url) => {
    return (dispatch) => {
        dispatch(getActivePromotionsStarted());
        API.get(`/api/promotion/getActive?url=${url}`).then(res => {
            dispatch(getActivePromotionsSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, getActivePromotionsFailure)
        })
    };
}


const getActivePromotionsStarted = () => ({
    type: GET_ACTIVE_PROMOTIONS_STARTED,
});

const getActivePromotionsSuccess = (promotion) => ({
    type: GET_ACTIVE_PROMOTIONS_SUCCESS,
    payload: {
        promotion,
    },
});

const getActivePromotionsFailure = (error) => ({
    type: GET_ACTIVE_PROMOTIONS_FAILURE,
    payload: { error },
});

//remove error message
const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});



//Handle catch error
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
    }

    dispatch(nextFunction(message));
    setTimeout(function () {
        dispatch(removeErrorMessage());
    }, 3000);
};