import {
    GET_ACTIVE_PROMOTIONS_STARTED,
    GET_ACTIVE_PROMOTIONS_SUCCESS,
    GET_ACTIVE_PROMOTIONS_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/promotion/promotion";

const initialState = {
    activePromotion: null,
    loading: false,
    error: null,
    message: null,
};

export default function promotion(state = initialState, action) {
    switch (action.type) {
        case GET_ACTIVE_PROMOTIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_ACTIVE_PROMOTIONS_SUCCESS:
            return {
                ...state,
                activePromotion: action.payload.promotion,
                loading: false,
            };

        case GET_ACTIVE_PROMOTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}