import React, { Component } from "react";
import { connect } from "react-redux";
import { checkingInWalkInAppointmentNoServices, getCheckingInSalonInfo } from "../../redux/actions/checking-in/checking-in";
import { NewMessageError } from "../../component/common/MessageError/NewMessageError";
import Loading from "../../component/common/Loading/Loading";
import CheckingPhoneContainer from "../../component/CheckingIn/CheckingPhoneContainer";
import "./CheckingIn.css";
import { Route, Switch, withRouter } from "react-router-dom";
import SelectServicesAndStaffs from "../../component/CheckingIn/SelectService/SelectServicesAndStaffs";
import ConfirmAppointment from "../../component/CheckingIn/ConfirmAppointment/ConfirmAppointment";
import { Layout, ConfigProvider } from "antd";
// import moment from "moment";
import Thankyou from "../../component/CheckingIn/Thankyou/Thankyou";
import queryString from "query-string";
import NotFoundPage from "../../component/common/NotFoundPage/NotFoundPage";
import { countryCurrencyCode } from "../../utils/util/utility"
import ClientLoyaltyPoints from "../../component/CheckingIn/Thankyou/ClientLoyaltyPoints";
import DupCheckedIn from "../../component/CheckingIn/DupCheckedIn/DupCheckedIn";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

const { Content } = Layout;

class CheckingIn extends Component {
    state = {
        isClientUrl: false,
        country: "CA"
    };

    handleChangeCountry = (country) => {
        this.setState({ country })
    }

    componentDidMount() {
        const location = this.props.match.params.location;


        if (location) {
            this.props.getCheckingInSalonInfo(location);
        }

        const queryObject = queryString.parse(
            this.props.history.location.search
        );

        if (queryObject.clientUrl) {
            this.setState({
                isClientUrl: true,
            });
            const localClientData = this.getLocalClientData();

            if (localClientData && localClientData.phone) {
                this.setState({
                    phone: localClientData.phone,
                });
            }
        }

        window.scrollTo(0, 0);
    }

    getLocalClientData = () => {
        const client = JSON.parse(localStorage.getItem("checkingInUser"));
        if (client) {
            return client;
        }
        return null;
    };


    getCurrency = () => {
        const country = this.state.country
        const currencyCode = countryCurrencyCode.filter(ctr => country === ctr.country)[0] || { country: 'CA', currency: '$' }
        return currencyCode.currency


    }

    isClientDetailMissing = () => {
        if (Object.keys(this.props.businessClient).length === 0) {
            return true
        }
        else {
            for (const key in this.props.businessClient) {
                if (this.props.businessClient[key] === "") return true
            }
        }
        return false
    }

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;

        if (!executeRecaptcha) {
            return null;
        }

        return await executeRecaptcha();

    };

    handleSubmitNoServices = async (businessClient) => {
        const location = this.props.match.params.location;

        // reCAPTCHA verification
        const token = await this.handleVerifyRecaptcha();

        this.props.checkingInWalkInAppointmentNoServices(
            location,
            { ...businessClient },
            token
        );

    }


    componentDidUpdate(prevProps) {
        const { error, isFetchingAppointment, business } = this.props;
        const location = this.props.match.params.location;

        if (prevProps.business !== this.props.business) {
            this.setState({ country: this.props.business.country })
        }


        if (prevProps.error !== error) {
            NewMessageError(null, error);
        }

        if (prevProps.alreadyCheckedIn !== this.props.alreadyCheckedIn && this.props.alreadyCheckedIn === true) {
            this.props.history.push(
                `/${location}/checking-in/dub-checked-in`
            );
        }
        else if (
            prevProps.appointments !== this.props.appointments &&
            isFetchingAppointment
        ) {
            if (this.props.appointments.length > 0) {
                this.props.history.push(
                    `/${location}/checking-in/confirm-appointment`
                );
            } else if (business.isCheckInWithServices) {
                this.props.history.push(
                    `/${location}/checking-in/select/services`
                );
            } else {
                if (this.isClientDetailMissing()) {
                    this.props.history.push(
                        `/${location}/checking-in/select/client-form`
                    );
                } else {
                    this.handleSubmitNoServices(this.props.businessClient)
                    this.props.history.push(
                        `/${location}/checking-in/select/confirmation-no-services`
                    );

                }
            }
        }

        // Thank you page after submissions from ConfirmAppointment & SelectServices
        if (prevProps.message !== this.props.message && this.props.message) {
            this.props.history.push(`/${location}/checking-in/thank-you`);
        }

        // Not found page
        if (
            this.props.redirectToNotFound &&
            prevProps.redirectToNotFound !== this.props.redirectToNotFound
        ) {
            this.props.history.push(`/${location}/checking-in/not-found`);
        }
    }




    render() {
        const { loading } = this.props;
        const location = this.props.match.params.location;
        const { isClientUrl } = this.state;
        const currency = this.getCurrency()

        if (loading) {
            return (
                <ConfigProvider
                    theme={{
                        token: {
                            // Seed Token
                            colorPrimary: '#45D3D3',
                            // borderRadius: 2,

                            // Alias Token
                            // colorBgContainer: '#f6ffed',
                        },
                    }}
                >
                    <div
                        className="checking-in-container "
                        style={{ paddingBottom: "100px" }}
                    >
                        <Loading />
                    </div>
                </ConfigProvider>
            );
        }


        return (
            <ConfigProvider
                theme={{
                    token: {
                        // Seed Token
                        colorPrimary: '#45D3D3',
                        // borderRadius: 2,

                        // Alias Token
                        // colorBgContainer: '#f6ffed',
                    },

                }}
            >
                <Layout
                    className="checking-in-container "
                    style={{ overflow: "auto" }}
                >
                    <Content>

                        <Switch>
                            <Route path="/:location/checking-in" exact>
                                <CheckingPhoneContainer
                                    history={this.props.history}
                                    location={location}
                                    isClientUrl={isClientUrl}
                                    handleChangeCountry={this.handleChangeCountry}
                                    country={this.state.country}
                                    currency={currency}
                                />
                            </Route>

                            <Route
                                path="/:location/checking-in/confirm-appointment"
                                exact
                            >
                                <ConfirmAppointment
                                    history={this.props.history}
                                    isClientUrl={isClientUrl}
                                    country={this.state.country}
                                    currency={currency}
                                    {...this.props}
                                />
                            </Route>

                            <Route path="/:location/checking-in/thank-you" exact>
                                <Thankyou
                                    history={this.props.history}
                                    handleChangeCountry={this.handleChangeCountry}
                                    {...this.props}
                                />
                                {this.props.clientLoyalty &&
                                    <ClientLoyaltyPoints />
                                }
                            </Route>

                            <Route path="/:location/checking-in/select">
                                <SelectServicesAndStaffs
                                    history={this.props.history}
                                    location={location}
                                    isClientUrl={isClientUrl}
                                    country={this.state.country}
                                    currency={currency}
                                    handleChangeCountry={this.handleChangeCountry}
                                />
                            </Route>

                            <Route path="/:location/checking-in/dub-checked-in">
                                <DupCheckedIn
                                    history={this.props.history}
                                    handleChangeCountry={this.handleChangeCountry}
                                    {...this.props}
                                />
                            </Route>

                            <Route
                                path="/*"
                                render={() => (
                                    <NotFoundPage />
                                )}
                            />
                        </Switch>

                    </Content>

                </Layout>

            </ConfigProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.checkingIn.loading,
    message: state.checkingIn.message,
    error: state.checkingIn.error,
    business: state.checkingIn.business,
    businessClient: state.checkingIn.businessClient,
    clientLoyalty: state.checkingIn.clientLoyalty,
    appointments: state.checkingIn.appointments,
    isFetchingAppointment: state.checkingIn.isFetchingAppointment,
    redirectToNotFound: state.checkingIn.redirectToNotFound,
    alreadyCheckedIn: state.checkingIn.alreadyCheckedIn
});

const mapDispatchToProps = (dispatch) => {
    return {
        getCheckingInSalonInfo: (url) => dispatch(getCheckingInSalonInfo(url)),
        checkingInWalkInAppointmentNoServices: (
            business,
            businessClient,
            token
        ) =>
            dispatch(
                checkingInWalkInAppointmentNoServices(
                    business,
                    businessClient,
                    token
                )
            ),
    };
};

export default withGoogleReCaptcha(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CheckingIn)
));
